<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">Create New User</h1>

    <div class="row mt-16">
      <div class="col col--9">
        <div class="box box--white box--lg">
          <form @submit.prevent="onRegister">
            <div class="form-group">
              <label class="form-label">Full Name</label>
              <input
                type="text"
                :class="['form-control', { 'form-control--error': $v.params.fullName.$error }]"
                v-model="$v.params.fullName.$model"
              />
              <p v-if="$v.params.fullName.$error" class="form-error-text">Name can't be blank</p>
            </div>

            <div class="form-group">
              <label class="form-label">Email</label>
              <input
                type="email"
                :class="['form-control', { 'form-control--error': $v.params.email.$anyError }]"
                v-model="$v.params.email.$model"
              />
              <p v-if="$v.params.email.$anyError" class="form-error-text">
                <span v-if="!$v.params.email.required">Email can't be blank</span>
                <span v-if="!$v.params.email.email">Email is invalid</span>
              </p>
            </div>

            <div class="form-group">
              <label class="form-label">Password</label>
              <input
                type="password"
                :class="['form-control', { 'form-control--error': $v.params.password.$anyError }]"
                v-model="$v.params.password.$model"
              />
              <p v-if="$v.params.password.$anyError" class="form-error-text">
                <span v-if="!$v.params.password.required">Password can't be blank</span>
                <span v-if="!$v.params.password.minLength">Password is too short (minimum is 6 characters)</span>
              </p>
            </div>

            <div class="form-group">
              <label class="form-label">Confirm password</label>
              <input
                type="password"
                :class="['form-control', { 'form-control--error': $v.params.confirmPassword.$error }]"
                v-model="$v.params.confirmPassword.$model"
              />
              <p v-if="$v.params.confirmPassword.$error" class="form-error-text">Password confirm must be identical</p>
            </div>

            <div class="form-group">
              <label class="form-label">Role</label>
              <br />
              <label class="form-radio">
                <input type="radio" value="2" v-model="params.role" /> Admin
                <span></span>
              </label>

              <label class="form-radio">
                <input type="radio" value="3" v-model="params.role" /> Member
                <span></span>
              </label>
            </div>

            <div class="mt-24">
              <button class="btn btn--primary btn--full">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import registerFormValidator from '@/validations/register-form'
import toggleLoading from '@/mixins/toggle-loading'

export default {
  data() {
    return {
      params: null
    }
  },

  mixins: [registerFormValidator, toggleLoading],

  methods: {
    ...mapActions('boUsers', ['register']),

    initParams() {
      this.params = {
        fullName: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: 2
      }
    },

    onGetDataRequest() {
      return {
        ...this.params,
        role: parseInt(this.params.role)
      }
    },

    onRegister() {
      this.$v.$touch()
      if (this.isFormValid) {
        this.toggleLoading()
        this.register(this.onGetDataRequest())
          .then(() => {
            this.$router.push({ name: 'ListAdmin' })
          })
          .finally(() => {
            this.toggleLoading()
          })
      }
    }
  },

  created() {
    this.initParams()
    this.toggleLoading()
  }
}
</script>
